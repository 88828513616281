import {
  useEffect,
  createContext,
  useState,
  useContext,
  useCallback,
} from 'react';
import { Users } from './../api-calls';

const initialUserState = {
  id: null,
  email: '',
  fullName: '',
  role: '',
  organisation: null,
  improvementScore: null,
  assessmentScore: null,
  deleted: false,
  selectedPath: null,
  membershipNo: null,
  district: null,
  fetched: false,
};

const AuthContext = createContext({
  user: initialUserState,
  setUser: () => {},
});

const getUserInfoFromStorage = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  return user && user.id ? user : initialUserState;
};

const storeUserInfoIntoStorage = (user) => {
  localStorage.setItem('user', JSON.stringify(user));
};

const clearUserInfoFromStorage = () => {
  localStorage.removeItem('user');
};

const AuthProvider = (props) => {
  const [user, setUser] = useState(getUserInfoFromStorage);

  const _setUser = useCallback((data) => {
    // Update localStorage
    storeUserInfoIntoStorage(data);
    // Update state
    setUser({ ...data, fetched: true });
  }, []);

  const getUserInfo = useCallback(async () => {
    try {
      const { data } = await Users.getLoggedInUserInfo();
      if (data) {
        _setUser({ ...data, fetched: true });
      } else {
        clearUserInfoFromStorage();
        setUser({ ...initialUserState, fetched: true });
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
      clearUserInfoFromStorage();
      setUser({ ...initialUserState, fetched: true });
    }
  }, [_setUser]);

  useEffect(() => {
    // Fetch user info on mount
    getUserInfo();

    // Listen for changes to localStorage in other tabs
    const handleStorageChange = (event) => {
      if (event.key === 'user') {
        const updatedUser = getUserInfoFromStorage();
        setUser({ ...updatedUser, fetched: true });
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [getUserInfo]);

  const value = {
    user,
    getUserInfo,
    setUser: _setUser,
  };

  return <AuthContext.Provider value={value} {...props} />;
};

const useAuth = () => {
  const value = useContext(AuthContext);
  return value;
};

export { AuthProvider, useAuth };
export default AuthContext;
