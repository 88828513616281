import styled from '@emotion/styled';

export const Button = styled.button`
  position: fixed;
  bottom: ${({ theme: { spacings } }) => spacings[5]};
  right: ${({ theme: { spacings } }) => spacings[4]};
  border-radius: 50px;
  border: 4px solid white;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.03))
    drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.05))
    drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.03));
  min-width: 152px;
  min-height: 57px;
  padding: ${({ theme: spacings }) => `${spacings[3]} ${spacings[2]}`};
  background: ${({ theme: { colors } }) => colors.purple};
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  :hover {
    min-height: 59px;
    min-width: 154px;
  }

  ${({ theme: { media } }) => media.tablet} {
    bottom: ${({ theme: { spacings } }) => spacings[4]};
    right: ${({ theme: { spacings } }) => spacings[3]};
  }

  ${({ theme: { media } }) => media.mobile} {
    bottom: ${({ theme: { spacings } }) => spacings[3]};
    right: ${({ theme: { spacings } }) => spacings[2]};
  }
`;
