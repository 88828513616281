import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Icon from '../Icon';

import * as S from './style';

const Button = (props) => {
  const {
    variant = 'primary',
    size = variant === 'secondary' ? 'small' : 'large',
    to,
    handleClick,
    loading,
    disabled,
    color,
    boldLine,
    children,
    href,
    textColor,
    textAlign,
    external,
    ...rest
  } = props;

  const history = useHistory();

  const onClick = (e) => {
    if (external) {
      e.preventDefault();
      window.open(href, '_blank', 'noreferrer');
    }
    if (!external && to) history.push(to);
    if (handleClick instanceof Function) handleClick(e);
  };

  let Component = to || href ? S.ButtonAsLink : S.Button;

  return (
    <Component
      onClick={onClick}
      variant={variant}
      type={to || href ? undefined : 'button'}
      size={size}
      disabled={disabled || loading}
      color={color}
      textColor={textColor}
      boldLine={boldLine}
      href={href}
      to={to}
      {...rest}
    >
      {loading && (
        <S.Loading
          indicator={
            <Icon icon="spinner" width="24px" height="24px" color={color} />
          }
        />
      )}
      {typeof children === 'string' ? (
        <S.Label
          color={color}
          as="span"
          variant={variant}
          isLoading={loading}
          size={size}
          textColor={textColor}
          textAlign={textAlign}
        >
          {children}
        </S.Label>
      ) : (
        children
      )}
    </Component>
  );
};

Button.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary', 'outlined', 'link']),
  size: PropTypes.oneOf(['small', 'large']),
  to: PropTypes.string,
  handleClick: PropTypes.func,
  color: PropTypes.string,
  textColor: PropTypes.string,
  href: PropTypes.string,
  bgColor: PropTypes.oneOf(['blue', 'teal', 'purple', 'litestGray']),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Button;
