import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as CS from '../style';
import Button from '../../Button';
import { BasicInput, Dropdown } from '../../Inputs';
import { dropdownData } from '../../../constants';
import { Skills } from '../../../api-calls';
import { navRoutes } from '../../../constants';
import { useAppStore } from '../../../context/appStore';

import validate from '../../../validation/schemas/search';

import { handleClickForAnalytics } from '../../../helpers';

const { toolDropdownData } = dropdownData;

const Search = ({
  color = 'teal',
  btnText = 'Search',
  withoutPaddings,
  withoutShadow,
  setResults,
  searchPage,
  scrollPosition,
  page,
  ...props
}) => {
  const { search, setSearch } = useAppStore();
  const [submitAttempt, setSubmitAttempt] = useState(false);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const searchParams = new URLSearchParams();

  const handleTask = (e) => {
    const { name, value } = e.target;
    setSearch({ ...search, [name]: value });
  };

  const sendTextInputToAnalytics = () => {
    handleClickForAnalytics({
      name: 'skills_search',
      category: 'skills_search',
      action:
        page === 'home'
          ? 'Search_widget_text_input_homepage'
          : 'Search_text_input',
    });
  };

  const handleTool = (e) => {
    setSearch({ ...search, tool: e });

    handleClickForAnalytics({
      name: 'skills_search',
      category: 'skills_search',
      action:
        page === 'home'
          ? 'Search_widget_drop-down select (homepage)'
          : 'Search_category_drop-down_select',
    });
  };

  const scrollToResults = () => {
    scrollPosition.current.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    if (!setResults) {
      search.task && searchParams.append('task', search.task);
      search.tool && searchParams.append('tool', search.tool);

      return history.push({
        pathname: navRoutes.GENERAL.SEARCH,
        search: searchParams.toString(),
      });
    }

    setSubmitAttempt(true);
    const isValid = validateForm();

    if (!isValid) return;

    let searchForm;
    if (search.tool === dropdownData.A_DIGITAL_TOOL) {
      searchForm = {
        task: search.task,
        tool: '',
      };
    } else {
      searchForm = {
        task: search.task,
        tool: search.tool,
      };
    }
    const { data, error } = await Skills.SearchSkillsAndActivities(searchForm);

    if (!error) {
      if (setResults) {
        scrollToResults();
        handleClickForAnalytics({
          name: 'skills_search',
          category: 'skills_search',
          action: 'Results_Listing_Page',
          label: data.length,
          priority: 'Low',
        });

        setResults(data);
      }
    }
  };

  useEffect(() => {
    // if redirected from landing page
    if (
      searchPage &&
      (search.task ||
        (search.tool && search.tool !== dropdownData.A_DIGITAL_TOOL))
    ) {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateForm = () => {
    try {
      validate({
        task: search.task,
        tool: search.tool,
      });
      setErrors({});
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setErrors(error.inner);
      }
      return false;
    }
  };

  useEffect(() => {
    if (submitAttempt) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.task, search.tool]);

  return (
    <CS.Wrapper
      color={color}
      withoutPaddings={withoutPaddings}
      withoutShadow={withoutShadow}
      {...props}
    >
      <form onSubmit={handleSubmit} role="search" aria-label="Search">
        <BasicInput
          aria-label="I need to learn how to"
          label="I need to learn how to"
          value={search.task}
          handleChange={handleTask}
          handleBlur={sendTextInputToAnalytics}
          color="white"
          outline
          margins={{ mb: '6' }}
          name="task"
          big
          placeholder="your task here..."
          id="task"
          error={errors.task}
          autoComplete={false}
        />
        <Dropdown
          label="using"
          aria-label="using"
          id="tool"
          selected={[search.tool]}
          handleChange={handleTool}
          options={toolDropdownData}
          big
          outline
          color="white"
          margins={{ mb: '6' }}
          error={errors.tool}
          showSearch={false}
          placeholder="Please select"
        />

        <Button type="submit" bgColor={color}>
          {btnText}
        </Button>
      </form>
    </CS.Wrapper>
  );
};

export default Search;
