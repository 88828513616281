import * as S from './style';
import * as T from '../../components/Typography';

const FollowUs = ({ linkProps }) => {
  return (
    <S.Socials>
      <T.Body16B color="white" display="block">
        Follow us
      </T.Body16B>
      <S.SocialsList>
        <li>
          <a href="https://twitter.com/scouts" {...linkProps}>
            <S.SocialImg
              src="https://cms.scouts.org.uk/media/i5gd1ivv/twitterx.png"
              alt="Scouts on X"
            />
          </a>
        </li>
        <li>
          <a href="https://www.tiktok.com/@scoutsuk" {...linkProps}>
            <S.SocialImg
              src="https://cms.scouts.org.uk/media/1ckfregn/tiktok.png"
              alt="Scouts on TikTok"
            />
          </a>
        </li>
        <li>
          <a
            href="https://www.facebook.com/scoutassociation/?locale=en_GB"
            {...linkProps}
          >
            <S.SocialImg
              src="https://cms.scouts.org.uk/media/kmynghfh/facebook.png"
              alt="Scouts on Facebook"
            />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/scouts/" {...linkProps}>
            <S.SocialImg
              src="https://cms.scouts.org.uk/media/o3wbjcsf/instagram.png"
              alt="Scouts on Instagram"
            />
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/user/ukscoutassociation"
            {...linkProps}
          >
            <S.SocialImg
              src="https://cms.scouts.org.uk/media/t4invage/youtube.png"
              alt="Scouts on YouTube"
            />
          </a>
        </li>
        <li>
          <a href="https://uk.linkedin.com/company/scoutsuk" {...linkProps}>
            <S.SocialImg
              src="https://cms.scouts.org.uk/media/g44fpic5/linkedin.png"
              alt="Scouts on LinkedIn"
            />
          </a>
        </li>
      </S.SocialsList>
    </S.Socials>
  );
};

export default FollowUs;
