import React, { useState } from 'react';
import * as S from './style';
import Icon from '../../Icon';
import theme from '../../../theme';
import Menu from '../../Menu';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import {
  GENERAL,
  EXTERNAL,
  DIGITAL_CHAMPION,
} from '../../../constants/nav-routes';
import { roles } from '../../../constants';
import { breakpoints } from '../../../theme';
import { useAuth } from '../../../context/auth';

const NavBar = () => {
  const { user } = useAuth();
  const [visible, setVisible] = useState(false);
  const {
    constants: {
      navBar: { drawer },
    },
  } = theme;

  const history = useHistory();
  const isTablet = useMediaQuery({
    query: `(max-width: ${breakpoints.desktopL})`,
  });

  return (
    <>
      <S.Navbar>
        <S.LinkContainer>
          <a
            aria-label="Scouts logo - link to Scouts website"
            href={EXTERNAL.SCOUTS}
          >
            <Icon icon="logo" color="black" />
          </a>
        </S.LinkContainer>
        <S.IconContainer>
          {!isTablet && <Menu fullScreen />}
          <S.Button
            aria-label="Search"
            icon={<Icon icon="search" />}
            onClick={() =>
              history.push(
                user.role === roles.DIGITAL_CHAMPION
                  ? DIGITAL_CHAMPION.FIND_SKILLS.replace(':q?', '')
                  : GENERAL.SEARCH
              )
            }
          />
          {isTablet && (
            <S.Button
              aria-label="Menu"
              icon={<Icon icon="burger" />}
              onClick={() => setVisible(true)}
            />
          )}
        </S.IconContainer>
        <S.Drawer
          placement="left"
          title={
            <a
              href={EXTERNAL.SCOUTS}
              target="_blank"
              rel="noreferrer"
              aria-label="Scouts homepage"
            >
              <Icon icon="logo" color="white" />
            </a>
          }
          onClose={() => setVisible(false)}
          visible={visible}
          mask={false}
          width={drawer.width}
          bodyStyle={{ background: theme.colors.blue }}
          headerStyle={{
            background: theme.colors.blue,
            border: 'none',
            height: drawer.headHeight,
          }}
          closeIcon={<Icon width="27" height="27" icon="close" color="white" />}
        >
          {<Menu closeDrawer={() => setVisible(false)} />}
        </S.Drawer>
      </S.Navbar>
    </>
  );
};

export const BurgerMenu = () => {
  const [visible, setVisible] = useState(false);
  const {
    constants: {
      navBar: { drawer },
    },
  } = theme;

  return (
    <>
      <S.BurgerMenu>
        <S.Button
          icon={<Icon icon="burger" />}
          onClick={() => setVisible(true)}
        />
      </S.BurgerMenu>
      <S.Drawer
        placement="left"
        title={
          <a href={EXTERNAL.SCOUTS} target="_blank" rel="noreferrer">
            <Icon icon="logo" color="white" />
          </a>
        }
        onClose={() => setVisible(false)}
        visible={visible}
        mask={false}
        width={drawer.width}
        bodyStyle={{ background: theme.colors.blue }}
        headerStyle={{
          background: theme.colors.blue,
          border: 'none',
          height: drawer.headHeight,
        }}
        closeIcon={<Icon width="27" height="27" icon="close" color="white" />}
      >
        <Menu closeDrawer={() => setVisible(false)} />
      </S.Drawer>
    </>
  );
};
export default NavBar;
