import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import * as T from '../Typography';
import * as S from './style';

import QuestionModal from '../QuestionModal';
const HelpButton = ({ handleClick, to }) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const onClick = (e) => {
    if (to) history.push(to);
    if (handleClick instanceof Function) handleClick(e);
    setOpen(true);
  };

  // auto close modal if route changes (to close modal when user click on a question)
  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <>
      <S.Button onClick={onClick}>
        <T.Body16B color="white">Still need help?</T.Body16B>
      </S.Button>
      {open && <QuestionModal open={open} setOpen={setOpen} />}
    </>
  );
};

export default HelpButton;
