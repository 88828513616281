import { useReducer, useEffect } from 'react';

import { BasicInput, Textarea } from '../Inputs';
import Button from '../Button';
import { Questions } from '../../api-calls';
import { askQuestion as validate } from '../../validation/schemas';
import * as T from '../Typography';
import { useAuth } from '../../context/auth';

import { handleClickForAnalytics } from '../../helpers';

import * as S from './style';

const cleanEmail = (email) => email.toLowerCase().trim();

const initialState = {
  name: null,
  email: null,
  question: '',

  httpError: '',
  validationErrs: {},
  submitAttempt: false,
};

function reducer(state, newState) {
  return { ...state, ...newState };
}

const QuestionForm = ({ setShowThankyou }) => {
  const [state, setState] = useReducer(reducer, initialState);
  const { user } = useAuth();

  const handleChange = (e) => {
    const { value, name } = e.target;
    setState({ [name]: value });
  };

  const validateForm = () => {
    try {
      validate({ ...state });
      setState({ validationErrs: {} });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({ validationErrs: error.inner });
      }
      return false;
    }
  };

  useEffect(() => {
    if (user.email) {
      setState({ email: user.email });
    }
    if (user.fullName) {
      setState({ name: user.fullName });
    }
  }, [user.fullName, user.email]);

  useEffect(() => {
    if (state.submitAttempt) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.name, state.email, state.question]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setState({ submitAttempt: true });

    const isValid = validateForm();
    if (isValid) {
      handleClickForAnalytics({
        name: 'ask_a_question_form',
        category: 'ask_a_question_form',
        action: 'Form_submit_fields_completed',
      });
      setState({ loading: true });

      const { error, inner } = await Questions.sendQuestion({
        form: {
          name: state.name,
          email: state.email && cleanEmail(state.email),
          question: state.question,
        },
      });
      if (error) {
        handleClickForAnalytics({
          name: 'ask_a_question_form',
          category: 'ask_a_question_form',
          action: 'Form_submit_error',
        });
        setState({
          httpError: error.message,
          loading: false,
          validationErrs: inner,
        });
      } else {
        handleClickForAnalytics({
          name: 'ask_a_question_form',
          category: 'ask_a_question_form',
          action: 'Form_submit_success',
        });
        setShowThankyou(true);
      }
    } else {
      handleClickForAnalytics({
        name: 'ask_a_question_form',
        category: 'ask_a_question_form',
        action: 'Form_validation_error',
      });
    }
  };

  return (
    <S.Form onSubmit={handleSubmit}>
      <BasicInput
        label={`Your name ${!user.fullName ? '(optional)' : ''}`}
        aria-label={`Your name ${!user.fullName ? '(optional)' : ''}`}
        placeholder=""
        name="name"
        value={state.name || ''}
        handleChange={handleChange}
        error={state.validationErrs.name}
        disabled={user.fullName}
        margins={{ mb: 4 }}
        id="name"
      />
      <BasicInput
        label="Email address"
        aria-label="Email address"
        placeholder=""
        name="email"
        value={state.email || ''}
        handleChange={handleChange}
        error={state.validationErrs.email}
        disabled={user.email}
        margins={{ mb: 4 }}
        id="email"
      />
      <Textarea
        label="Your question"
        aria-label="Your question"
        placeholder="Your question..."
        type="textarea"
        name="question"
        rows="3"
        cols="33"
        value={state.question}
        handleChange={handleChange}
        error={state.validationErrs.question}
        margins={{ mb: 4 }}
        id="question"
      />
      {state?.httpError && (
        <S.Container>
          <T.Body16 mt="5" color="red">
            {state?.httpError}
          </T.Body16>
        </S.Container>
      )}
      <S.ButtonContainer>
        <Button
          variant="primary"
          type="submit"
          loading={state.loading}
          disabled={Object.keys(state.validationErrs).length}
        >
          Send
        </Button>
      </S.ButtonContainer>
    </S.Form>
  );
};

export default QuestionForm;
