import styled from '@emotion/styled';
import { Spin } from 'antd';
import * as T from '../Typography';
import setMargin from './../../helpers/set-margin';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';

const heights = {
  small: 48,
  large: 56,
};

const commonButtonStyles = ({
  theme,
  variant,
  size,
  w,
  bgColor,
  color: _color,
  boldLine,
  textColor,
  disabled,
}) => {
  let color = bgColor ? theme.colors[bgColor] : theme.colors.blue;
  if (variant === 'outlined' || variant === 'link') {
    color = 'none';
  }
  return css`
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: auto;
    padding-top: ${theme.spacings[2]};
    padding-bottom: ${theme.spacings[2]};
    min-height: ${heights[size]}px;
    width: ${w || '100%'};
    color: ${variant === 'link'
      ? theme.colors[_color] || theme.colors.liteGray
      : undefined};
    background: ${disabled ? theme.colors.gray : color || bgColor};
    border: ${variant === 'outlined'
      ? `${boldLine ? '4px' : '1px'} solid ${
          theme.colors[_color] || theme.colors.teal
        }`
      : `${boldLine ? '4px' : '1px'} solid ${
          textColor ? theme.colors[textColor] : theme.colors.white
        }`};

    /* for disabled style */
    opacity: ${disabled ? '0.9' : '1'};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
  `;
};

export const Button = styled.button`
  -webkit-appearance: none !important;
  -webkit-border-radius: 0;
  ${setMargin};
  ${commonButtonStyles}
`;
export const ButtonAsLink = styled(Link)`
  -webkit-appearance: none !important;
  -webkit-border-radius: 0;
  ${setMargin};
  ${commonButtonStyles}
  pointer: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const Loading = styled(Spin)`
  span {
    line-height: 24px;
    color: ${({ variant, theme }) =>
      variant === 'outlined' ? theme.colors.teal : 'white'};
  }
`;

export const Label = styled(T.BodyB)`
  font-size: ${({ size }) => (size === 'small' ? '16px' : '18.66px')};
  color: ${({ variant, theme, color: _color, textColor }) => {
    let color =
      variant === 'outlined'
        ? theme.colors[_color] || theme.colors.teal
        : 'white';
    if (variant === 'link') {
      color = theme.colors[_color] || theme.colors.liteGray;
    }
    if (textColor) {
      color = textColor;
    }
    return color;
  }};
  margin-left: ${({ isLoading, theme }) =>
    isLoading ? theme.spacings['4'] : theme.spacings['3']};
  margin-right: ${({ theme }) => theme.spacings['3']};
  padding: 0 !important;
  text-align: ${({ textAlign }) => textAlign};
`;
