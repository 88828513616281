import * as T from '../../Typography';
import * as S from './style';
import { format } from 'date-fns';
import IconWithTextButton from '../../IconWithTextButton';

const SuggestedSkill = ({
  learnerName,
  learnerEmail,
  date,
  text,
  agreeToFollowUp,
  skillAreaTitle,
  archived,
  onClickArchive,
}) => {
  return (
    <S.Wrapper>
      {agreeToFollowUp ? (
        <T.Link16
          underline={false}
          href={`mailto:${learnerEmail}`}
          target="_blank"
          color="blue"
          fontSize="18px"
        >
          {learnerName}
        </T.Link16>
      ) : (
        <T.Body16B color="blue" fontSize="18px">
          {learnerName}
        </T.Body16B>
      )}

      <T.BodySM color="gray">
        <bold>Sent:</bold> {format(new Date(date), 'dd MMM, yyyy')}
      </T.BodySM>
      <T.BodySM color="gray">
        <bold>Skill Area:</bold> {skillAreaTitle}
      </T.BodySM>
      <T.Body16 color="gray" mt={2}>
        {text}
      </T.Body16>
      <IconWithTextButton
        mt={3}
        text={archived ? 'Unarchive' : 'Archive'}
        color="blue"
        icon={archived ? 'cross' : 'tick'}
        iconProps={{ width: 16, height: 16, pointer: true }}
        onClick={onClickArchive}
      />
    </S.Wrapper>
  );
};

export default SuggestedSkill;
