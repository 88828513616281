const colors = {
  pink: '#FFB4E5',
  blue: '#006ddf',
  green: '#008A1C',
  liteGreen: '#EDFFF1',
  liteBlue: '#E6EFFA',
  teal: '#088486',
  mediumTeal: '#61C5BA',
  liteTeal: '#CCEDEA',
  purple: '#6310bc',
  medPurple: '#7413DC',
  lightPurple: '#9B51E0',
  lighterPurple: '#f8f3fe',
  lighterRed: '#FFEEED',
  navy: '#002F6C',
  black: ' #000000',
  gray: '#404040',
  liteGray: '#6E6E6E',
  error: '#E22E12',
  literGray: '#E5E5E5',
  litestGray: '#F1F1F1',
  white: '#FFFFFF',
  gray1: '#cccccc',
  blackDark: '#000',
  red: '#CA1A10',
  yellow: '#FFE627',
  grayScorpion: '#595959',
};

export default colors;
