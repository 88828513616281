import styled from '@emotion/styled';
import setMargin from './../../helpers/set-margin';
import { Link as RLink } from 'react-router-dom';

export const Background = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 47, 108, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  overflow-y: auto;
  ${({ theme }) => theme.media.mobile} {
    background: ${({ theme }) => theme.colors.white};
  }
  z-index: 1;
`;

export const ModalWrapper = styled.div`
  max-width: 620px;
  width: 100%;
  background: ${({ bgColor, theme }) =>
    bgColor ? theme.colors[bgColor] || bgColor : theme.colors.white};
  position: relative;
  z-index: 10;
  width: 100%;
  height: auto;
  overflow-y: auto;
  box-shadow: 0px 20px 25px -5px rgba(16, 24, 40, 0.1),
    0px 8px 10px -6px rgba(16, 24, 40, 0.1);
  ${({ theme }) => theme.media.mobile} {
    max-width: 100%;
    min-height: 100vh;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 620px;
  display: block;
  margin-top: 15vh;
  ${({ theme }) => theme.media.mobile} {
    max-width: 100%;
    margin-top: 0;
  }
`;

export const Container = styled.div`
  ${setMargin};
  padding-left: 72px;
  padding-right: 72px;
  padding-bottom: ${({ theme }) => theme.spacings[6]};
  ${({ theme }) => theme.media.mobile} {
    padding-left: ${({ theme }) => theme.spacings[4]};
    padding-right: ${({ theme }) => theme.spacings[4]};
  }
`;

export const CloseModalButton = styled.button`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
  background: none;
  border: none;
  font-size: 24px;
  font-weight: bold;
`;

export const Form = styled.form`
  padding-left: 72px;
  padding-right: 72px;
  padding-bottom: ${({ theme }) => theme.spacings[6]};
  ${({ theme }) => theme.media.mobile} {
    padding-left: ${({ theme }) => theme.spacings[4]};
    padding-right: ${({ theme }) => theme.spacings[4]};
  }
`;

export const InputContainer = styled.div`
  background: ${({ theme }) => theme.colors.litestGray};
`;

export const QuestionsContainer = styled.div`
  padding-top: ${({ theme, removePadding }) =>
    theme.spacings[removePadding ? 0 : 3]};
  padding-bottom: ${({ theme, removePadding }) =>
    theme.spacings[removePadding ? 0 : 3]};
`;

export const QuestionContainer = styled(RLink)`
  display: flex;
  width: 100%;
  padding-top: ${({ theme }) => theme.spacings[3]};
  padding-bottom: ${({ theme }) => theme.spacings[3]};
  margin-bottom: ${({ theme }) => theme.spacings[2]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray1};
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  max-width: 343px;
  ${({ theme }) => theme.media.mobile} {
    max-width: 100%;
  }
`;
