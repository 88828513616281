import styled from '@emotion/styled';

export const FullScreenContentWrapper = styled.div`
  ${({ theme }) => theme.horizontalPaddings};
  flex: 1;
  padding-top: ${({ pt }) => pt || '32px'};
  padding-bottom: ${({ pb }) => pb || '64px'};

  ${({ theme }) => theme.media.mobile} {
    padding-top: ${({ ptm }) => ptm || '16px'};
  }
`;
