import { useState } from 'react';
import { Select as AntdSelect } from 'antd';
import * as T from '../../Typography';
import * as S from './style';
import { colors } from '../../../theme/index';
import Icon from '../../Icon';

const { Option: AntdOption, OptGroup: AntdOptGroup } = AntdSelect;

const Dropdown = ({
  handleChange,
  label,
  color,
  placeholder = 'Select...',
  error,
  isOptional,
  helper,
  additionalHelper,
  w,
  disabled,
  options,
  groupedOptions,
  selected,
  multi,
  mode,
  matrix,
  margins = {},
  big,
  outline,
  allowClear,
  onClear,
  showSearch,
  showArrow = true,
  showSearchIcon,
  showClearIcon,
  clearFunc,
  searchFunc,
  borderColor,
  isMobile,
  customOptions,
  id,
}) => {
  const [open, setOpen] = useState(false);
  const [focus, setFocus] = useState(false);

  const decideColor = () => {
    if (error) return 'error';
    return color;
  };

  const isSelected = (option) =>
    selected === option || (selected && selected.toString().includes(option));

  const renderOptions = () => {
    if (customOptions) {
      return options;
    }
    if (groupedOptions) {
      return options?.map(({ groupLabel, options: _options }) => (
        <AntdOptGroup
          aria-label={groupLabel}
          label={groupLabel}
          key={groupLabel}
        >
          {_options.map((opt) => (
            <AntdOption
              key={`${groupLabel}_${opt.label}`}
              aria-label={opt.label}
              value={opt.value}
              points={opt.points}
              style={{
                fontSize: '1rem',
                fontWeight: 'normal',
                backgroundColor: isSelected(opt.value)
                  ? colors.litestGray
                  : 'white',
                padding: '0.5rem 1rem',
                color: colors.black,
                display: 'flex',
                alignItems: 'center',
                whiteSpace: 'normal',
              }}
            >
              {opt.label}
            </AntdOption>
          ))}
        </AntdOptGroup>
      ));
    }
    return options?.map(
      ({ value: _value, label: _label, points: _points, disabled }) => (
        <AntdOption
          key={matrix ? `${label}_${_value}` : _label}
          aria-label={_label}
          value={_value}
          label={_label}
          points={_points}
          disabled={disabled}
          style={{
            fontSize: '1rem',
            backgroundColor: isSelected(_value) ? colors.litestGray : 'white',
            padding: '0.5rem 1rem',
            color: colors.black,
            display: 'flex',
            alignItems: 'center',
            whiteSpace: 'normal !important',
            height: 'auto',
          }}
        >
          {_label}
        </AntdOption>
      )
    );
  };

  const handleSearch = (input, option, ...rest) => {
    if (option.children) {
      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        ? true
        : false;
    } else if (option.label) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        ? true
        : false;
    }
  };
  return (
    <S.Field
      w={w}
      disabled={disabled}
      open={open}
      focus={focus}
      multi={multi}
      color={decideColor()}
      matrix={matrix}
      big={big}
      outline={outline}
      borderColor={borderColor}
      showSearchIcon={showSearchIcon}
      showSearch={showSearch}
      showClearIcon={showClearIcon && selected && selected.length > 0}
      mb={(margins && margins.mb) || '3'}
      {...margins}
    >
      {label && (
        <S.Label htmlFor={id} matrix={matrix}>
          {big ? (
            <T.H2 override="h6" color={decideColor()} m="0" mb="3" ml="1">
              {label}
            </T.H2>
          ) : (
            <T.Body16B color={decideColor()} m="0" mb="1" ml="1">
              {label}
            </T.Body16B>
          )}
          {helper && (
            <T.Body16 color={decideColor()} m="0" mb="1" ml="1">
              {helper}
            </T.Body16>
          )}
          {isOptional && (
            <T.Body16 color="liteGray" m="0" mb="1" ml="1">
              (optional)
            </T.Body16>
          )}
        </S.Label>
      )}
      {additionalHelper && (
        <T.Body16 color={decideColor()} m="0" mb="1" ml="1">
          {additionalHelper}
        </T.Body16>
      )}
      <S.Answer>
        <S.Wrapper>
          <AntdSelect
            value={selected || undefined}
            onSelect={multi ? undefined : handleChange}
            onChange={multi ? handleChange : undefined}
            mode={mode || (multi && 'multiple')}
            tokenSeparators={mode === 'tags' && [',']}
            placeholder={placeholder || 'Type here...'}
            showArrow={showArrow}
            allowClear={allowClear}
            onClear={onClear}
            onDropdownVisibleChange={(open) => setOpen(open)}
            dropdownStyle={S.menuStyle}
            menuItemSelectedIcon={
              <Icon icon="tick" width="24" height="24" color="teal" />
            }
            disabled={disabled}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            filterOption={handleSearch}
            showSearch={showSearch}
            id={id}
          >
            {renderOptions()}
          </AntdSelect>
          {(showSearchIcon || showClearIcon) && (
            <S.IconsWrapper>
              {selected && selected.length > 0 && showClearIcon && (
                <S.CloseButton onClick={() => clearFunc && clearFunc()}>
                  <Icon
                    icon="close"
                    width={isMobile ? '14' : '18'}
                    height={isMobile ? '14' : '18'}
                    color="gray"
                  />
                </S.CloseButton>
              )}

              {showSearchIcon && (
                <S.SearchButton
                  aria-label="Search"
                  onClick={() => searchFunc && searchFunc()}
                >
                  <Icon icon="search" color="gray" width="20" height="20" />
                </S.SearchButton>
              )}
            </S.IconsWrapper>
          )}
        </S.Wrapper>

        {error && (
          <T.Body16 color="error" m="0" mt="1">
            {error}
          </T.Body16>
        )}
      </S.Answer>
    </S.Field>
  );
};

export default Dropdown;
